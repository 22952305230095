<template>
  <div class="index-wrapper">
    <div>
      <div class="banner">
        <swiper
          ref="mySwiper"
          :options="bannerSwiper.swiperOptions"
          class="banner-swiper"
          @ready="swiperLoad('bannerSwiper', 'mySwiper')"
          @slideChangeTransitionEnd="slideChnage('bannerSwiper')"
        >
          <template
            v-for="(item, index) in (this.$i18n.locale === 'zh' ? bannerList : bannerListEn)"
          >
            <swiper-slide
              class="banner-swiper-slide"
              :key="index"
            >
              <img :src="item" />
            </swiper-slide>
          </template>
        </swiper>
        <div class="banner-pagination">
          <div class="banner-pagination-activeIndex">{{ bannerSwiper.activeIndex >= 10 ? bannerSwiper.activeIndex : '0' + bannerSwiper.activeIndex}}</div>
          <div class="banner-pagination-total">/ {{ bannerList.length >= 10 ? bannerList.length : '0' + bannerList.length }}</div>
          <div class="banner-pagination-prew-next">
            <div class="banner-swiper-button-prev">
              <img src="@/assets/image/banner/prev.png" />
              {{ $t('prev') }}
            </div>
            <div class="banner-swiper-button-next">
              {{ $t('next') }}
              <img src="@/assets/image/banner/next.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="section-1200 section-one">
        <div class="left-img">
          <img class="image-transition" :src="this.$i18n.locale === 'zh' ? sectionOne[0] : sectionOne[1]" />
          <div class="play">
            <img @click="visible = true" src="@/assets/image/section/play.png" alt="">
          </div>
        </div>
        <div class="right-text">
          <div class="right-text-title">
            <p>{{ $t('indexSectionOne.title') }}</p>
            <p>{{ $t('indexSectionOne.one') }}</p>
          </div>
          <div class="right-text-text">{{ $t('indexSectionOne.two') }}</div>
          <div class="right-text-list">
            <p>{{ $t('indexSectionOne.three') }}</p>
            <p>{{ $t('indexSectionOne.four') }}</p>
            <p>{{ $t('indexSectionOne.five') }}</p>
          </div>
          <div @click="$router.push('/product/skylightLamp')" class="section-more right-text-more"> {{ $t('more') }} ></div>
        </div>
      </div>
      <div class="section-two">
        <div class="section-title-wrapper">
          <div
            v-html="$t('indexSectionTwoTwo')"
            class="section-title"
          ></div>
          <!-- <div @click="$router.push('/resource/lightHealth')" class="section-more">{{ $t('more') }} ></div> -->
        </div>
        <div class="section-two-top">
          <div class="section-two-list">
            <el-image
              fit="cover"
              :src="sectionTwo[0]"
              :preview-src-list="[sectionTwo[0]]">
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div class="section-two-triangle">
              <img src="@/assets/image/triangle.png" />
            </div>
          </div>
          <div class="section-two-list">
            <div class="section-two-list-section">
              <div>{{ $t('indexSectionTwo.one') }}</div>
              <div>{{ $t('indexSectionTwo.two') }}</div>
            </div>
          </div>
          <div class="section-two-list">
            <el-image
              fit="cover"
              :src="sectionTwo[1]"
              :preview-src-list="[sectionTwo[1]]">
            >
              <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
            </el-image>
            <div class="section-two-triangle">
              <img src="@/assets/image/triangle.png" />
            </div>
          </div>
        </div>
        <div class="section-two-buttom">
          <div class="section-two-list">
            <div class="section-two-list-section">
              <div>{{ $t('indexSectionTwo.three') }}</div>
              <div>{{ $t('indexSectionTwo.four') }}</div>
            </div>
          </div>
          <div class="section-two-list">
            <div class="section-two-list">
              <el-image
                fit="cover"
                :src="sectionTwo[2]"
                :preview-src-list="[sectionTwo[2]]">
              >
                <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
              </el-image>
              <div class="section-two-triangle flip">
                <img src="@/assets/image/triangle.png" />
              </div>
            </div>
          </div>
          <div class="section-two-list">
            <div class="section-two-list-section">
              <div>{{ $t('indexSectionTwo.five') }}</div>
              <div>{{ $t('indexSectionTwo.six') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-1200 section-three">
        <div class="section-title-wrapper">
          <div
            v-html="$t('indexSectionThree.one')"
            class="section-title"
          ></div>
          <div @click="$router.push('/resource/lightHealth')" class="section-more">{{ $t('more') }} ></div>
        </div>
        <div class="section-three-list">
          <div class="section-three-list-top">
            <div class="section-three-list-left">
              <el-image
                :src="sectionThree[0]"
              >
                <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
              </el-image>
              <div class="section-three-list-line"></div>
            </div>
            <div class="section-three-list-right">
              <div>{{ $t('indexSectionThree.two') }}</div>
              <div>{{ $t('indexSectionThree.three') }}</div>
            </div>
          </div>
          <div class="section-three-list-bottom">
            <div class="section-three-list-right">
              <div>{{ $t('indexSectionThree.four') }}</div>
              <div>{{ $t('indexSectionThree.five') }}</div>
              <div class="section-three-list-line"></div>
            </div>
            <div class="section-three-list-left">
              <el-image
                :src="sectionThree[1]"
              >
                <div class="section-two-list-img-text" slot="error">{{ $t('imgError') }}</div>
              </el-image>
            </div>
          </div>
        </div>
      </div>
      <div class="section-four">
        <div class="section-four-title">
          <div class="section-title-wrapper">
            <div
              v-html="$t('indexSectionFour.title')"
              class="section-title"
            ></div>
          </div>
          <div class="section-four-pagination">
            <div class="section-four-swiper-button-prev">
              <img src="@/assets/image/section/swiper-pagination.png" />
            </div>
            <div class="section-four-swiper-button-next">
              <img src="@/assets/image/section/swiper-pagination.png" />
            </div>
          </div>
        </div>
        <div class="section-four-swiper">
          <swiper
            :options="sectionFourSwiper.swiperOptions"
          >
            <template
              v-for="(item, index) in sectionFour"
            >
              <swiper-slide
                class="section-four-swiper-slide"
                :key="index"
                @click.native="$router.push(item.link)"
              >
                  <img :src="item.imgage" />
                  <div class="section-four-swiper-slide-html" v-html="$t(item.prop)"></div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      </div>
      <div class="section-1200 section-five">
        <div class="section-four-title">
          <div class="section-title-wrapper">
            <div
              v-html="$t('indexSectionFive')"
              class="section-title"
            ></div>
          </div>
        </div>
        <div class="section-five-swiper">
          <swiper
            :options="sectionFiveSwiper.swiperOptions"
          >
            <template
              v-for="(item, index) in computedList"
            >
              <swiper-slide
                class="section-five-swiper-slide"
                :key="index"
              >
                <div @click="newsClick(item)" class="section-five-side-wrapper">
                  <div class="section-five-side-content">
                    <div class="section-five-side-content-title">
                      {{ item.title }}
                    </div>
                    <div class="section-five-side-content-des">
                      {{ item.desction }}
                    </div>
                  </div>
                  <div class="section-five-side-content-date">
                    <div>{{ time(item.show_time, 'day') }}</div>
                    <div>{{ time(item.show_time) }}</div>
                  </div>
                  <div class="section-five-side-content-image">
                    <img :src="item.cover" />
                  </div>
                </div>
              </swiper-slide>
            </template>
          </swiper>
          <div class="section-five-pagination" slot="pagination"></div>
          <div class="section-five-swiper-background">
            <div></div>
          </div>
        </div>
      </div>
      <el-dialog
        v-if="visible"
        :visible.sync="visible"
        width="80%"
      >
        <video
          v-if="this.$i18n.locale === 'zh'"
          autoplay
          controls
          src="@/assets/image/index-video.mp4"
        />
        <video
          v-else
          autoplay
          controls
          src="@/assets/image/video-en.mp4"
        />
      </el-dialog>
    </div>
  </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { configIndex } from '@/utils/index'
import { formatTime, goGet } from '@/utils'
export default {
  name: 'Index',
  metaInfo() {
    return {
      title: this.$t('indexTitle')
    }
  },
  components:{
    Swiper,
    SwiperSlide
  },
  computed: {
    sectionFiveComputed() {
      return configIndex.sectionFive.filter(list => list.lang === this.$i18n.locale)
    },
    computedList() {
      return this.newsList.filter(list => list.lang === this.$i18n.locale)
    },
  },
  data() {
    const { bannerList, bannerListEn, sectionTwo, sectionThree, sectionFour, sectionOne } = configIndex
    return {
      isLoad: false,
      bannerList,
      bannerListEn,
      bannerSwiper: {
        swiper: null,
        swiperOptions: {
          loop: true,
          navigation: {
            prevEl: '.banner-swiper-button-prev',
            nextEl: '.banner-swiper-button-next'
          }
        },
        activeIndex: null,
      },
      sectionOne,
      sectionTwo,
      sectionThree,
      sectionFour,
      sectionFourSwiper: {
        swiperOptions: {
          loop: false,
          autoplay: {
            delay: 1000,
            disableOnInteraction: false
          },
          slidesPerView: 'auto',
          spaceBetween: 20,
          navigation: {
            prevEl: '.section-four-swiper-button-prev',
            nextEl: '.section-four-swiper-button-next'
          }
        }
      },
      sectionFiveSwiper: {
        swiperOptions: {
          loop: false,
          effect : 'fade',
          fadeEffect: {
            crossFade: true,
          },
          pagination: {
            el: '.section-five-pagination',
            clickable: true
          }
        }
      },
      visible: false,
      newsList: [],
      videoUrl: process.env.VUE_APP_API + '/static/other/index-video.mp4'
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      goGet('/ewinlight/article/list', {}, false).then(res => {
        this.isLoad = true
        this.newsList = res.data.map(list => {
          list.cover = list.cover.replace('http://192.168.2.245:1500', process.env.VUE_APP_API)
          return list
        })
      })
    },
    time(date, type) {
      return formatTime(type || 'yearMonth', date)
    },
    newsClick(item) {
      this.$router.push('/article/' + item.id)
    },
    swiperLoad(prop, refName) {
      this[prop].swiper = this.$refs[refName].$swiper
      this[prop].activeIndex = this.getActiveIndex(prop)
    },
    getActiveIndex(prop) {
      return this[prop].swiper.realIndex + 1
    },
    slideChnage(prop) {
      this[prop].activeIndex = this.getActiveIndex(prop)
    }
  }
}
</script>

<style lang="scss" scoped>
.index-wrapper {
  width: 100%;
  overflow: hidden;
  .el-image {
    ::v-deep img {
      transform: scale(1);
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.04);
        transition: transform 0.3s;
      }
    }
  }
  .banner {
    width: 100%;
    height: 850px;
    overflow: hidden;
    position: relative;
    .banner-swiper {
      width: 100%;
      height: 850px;
      overflow: hidden;
       ::v-deep .banner-swiper-slide {
        flex-shrink: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        overflow: hidden;
      }
    }
    .banner-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 841px;
      height: 60px;
      background: #fff;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .banner-pagination-activeIndex {
        color: rgba(51, 51, 51, 1);
        font-size: 56px;
        font-weight: bold;
        font-family: 'myFirstFont';
        // margin-left: auto;
      }
      .banner-pagination-total {
        position: absolute;
        bottom: 60px;
        font-family: 'myFirstFont';
        font-size: 24px;
        color: #fff;
        font-weight: bold;
        text-indent: 100px;
        font-family: 'mySecondFont';
      }
      .banner-pagination-prew-next {
        position: absolute;
        top: 0;
        height: 60px;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        cursor: pointer;
        & > div {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 4px;
          }
        }
        .banner-swiper-button-prev {
          margin-right: 28px;
        }
      }
    }
  }
  .section-1200 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .section-title-wrapper {
    display: flex;
    height: 80px;
    justify-content: space-between;
    align-items: flex-end;
    .section-more {
      width: auto;
      
    }
    .section-title {
      font-size: 36px;
      color: rgba(51, 51, 51, 1);
      font-weight: bold;
      font-family: 'mySecondFont';
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      ::v-deep p:nth-child(1) {
        font-size: 44px;
      }
      ::v-deep p:nth-child(2) {
        margin-top: 0px;
      }
    }
  }
  .section-more {
    width: 100%;
    text-align: right;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    font-family: 'mySecondFont';
    line-height: 32px;
    cursor: pointer;
    &.right-text-more {
      margin-top: 68px;
      text-align: left;
    }
  }
  .image-transition {
    transition: transform 0.3s;
    transform: scale(1.0);
    &:hover {
      transform: scale(1.0);
      transition: transform 0.3s;
    }
  }
  .section-one {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 110px;
    .left-img {
      flex: 667;
      max-width: 667px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      overflow: hidden;
      position: relative;
      .play {
        position: absolute;
        width: 64px;
        height: 64px;
        cursor: pointer;
      }
    }
    .right-text {
      flex: 533;
      padding-left: 80px;
      .right-text-title {
        font-size: 26px;
        color: rgba(51, 51, 51, 1);
        font-weight: bold;
        font-family: 'mySecondFont';
        p {
          line-height: 37px;
        }
      }
      .right-text-text {
        margin-top: 25px;
        color: rgba(80, 80, 80, 1);
        font-size: 16px;
        line-height: 32px;
      }
      .right-text-list {
        margin-top: 25px;
        color: rgba(80, 80, 80, 1);
        font-size: 16px;
        p {
          line-height: 32px;
        }
      }
    }
  }
  .section-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    .section-title-wrapper {
      width: 1200px;
      margin: 0 auto;
      margin-bottom: 70px;
      height: 80px;
      display: flex;
    }
    & > div {
      display: flex;
      height: 440px;
      width: 100%;
      .section-two-list {
        flex: 1;
        height: 100%;
        background: rgb(219, 219, 219);
        display: flex;
        justify-content: center;
        align-self: center;
        position: relative;
        .section-two-triangle {
          position: absolute;
          left: 20%;
          bottom: -3px;
          display: flex;
          &.flip {
            transform: rotate(180deg);
            top: -3px;
            bottom: auto;
          }
        }
        .section-two-list-section {
          width: 100%;
          height: 100%;
          background: rgba(245, 245, 245, 1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 15%;
          & > div:nth-child(1) {
            color: rgba(51, 51, 51, 1);
            font-size: 26px;
            font-weight: bold;
            font-family: 'mySecondFont';
            line-height: 37px;
          } 
          & > div:nth-child(2) {
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            margin-top: 15px;
            line-height: 26px;
          } 
        }
        ::v-deep .section-two-list-img-text {
          color: #666666;
          font-size: 25px;
          line-height: 440px;
        }
      }
    }
  }
  .section-three {
    height: 1035px;
    padding-top: 120px;
    .section-three-list {
      display: flex;
      flex-direction: column;
      margin-top: 70px;
      & > div {
        display: flex;
        height: 388px;
        &.section-three-list-bottom {
          margin-top: -50px;
          .section-three-list-right {
            justify-content: flex-end;
            padding-bottom: 25px;
            align-items: flex-end;
            background: none;
            .section-three-list-line {
              bottom: 110px;
              top: auto;
            }
          }
        }
        & > div {
          width: calc(50% - 16px);
          height: 100%;
          background: rgb(245, 245, 245);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          ::v-deep .section-two-list-img-text {
            color: rgba(102, 102, 102, 1);
            font-size: 25px;
          }
          .section-three-list-line {
            position: absolute;
            width: 86px;
            height: 3px;
            background: rgba(51, 51, 51, 1);
            right: -43px;
            top: 44px;
            z-index: 10;
          }
          &.section-three-list-right {
            margin-left: 32px;
            background: #fff;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0 60px;
            padding-top: 21px;
            box-sizing: border-box;
            & > div:nth-child(1) {
              color: rgba(51, 51, 51, 1);
              font-size: 26px;
              font-weight: bold;
              font-family: 'mySecondFont';
            }
            & > div:nth-child(2) {
              color: rgba(80, 80, 80, 1);
              font-size: 16px;
              margin-top: 15px;
              line-height: 26px;
            }
          }
        }
      }
    }
  }
  .section-four {
    margin-bottom: 120px;
    .section-four-title {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .section-four-pagination {
        display: flex;
        &>div {
          width: 48px;
          height: 48px;
          cursor: pointer;
          margin: 0 20px;
          &:nth-child(2) {
            margin-right: 0;
            transform: rotate(180deg);
          }
        }
      }
    }
    .section-four-swiper {
      width: 100%;
      height: 593px;
      overflow: hidden;
      margin-top: 72px;
      ::v-deep .section-four-swiper-slide {
        overflow: hidden;
        width: 493px;
        position: relative;
        cursor: pointer;
        &:hover {
          &>img {
            transition: all 0.3s;
            transform: scale(1.04);
          }
        }
        &>img {
          transition: all 0.3s;
        }
        .section-four-swiper-slide-html {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 257px;
          padding: 35px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background: url('~@/assets/image/section/s7.png');
          & > p {
            color: #fff;
            font-size: 38px;
            line-height: 40px;
            font-weight: bold;
            font-family: 'mySecondFont';
            &:nth-child(2) {
              font-size: 28px;
              margin-top: 10px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .section-five-swiper {
    width: 100%;
    position: relative;
    margin-bottom: 120px;
    .swiper-container {
      overflow: visible;
    }
    .section-five-swiper-background {
      margin-top: 46px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 505px;
      & > div {
        width: 82.5%;
        height: 505px;
        background: url('~@/assets/image/section/news-douhao.png') rgba(245, 245, 245, 1) no-repeat 8% 90px;
      }
    }
    ::v-deep .section-five-swiper-slide {
      margin-top: 46px;
      position: relative;
      .section-five-side-wrapper {
        width: 82.5%;
        height: 505px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .section-five-side-content {
          width: 55%;
          text-align: right;
          .section-five-side-content-title {
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            font-family: 'mySecondFont';
            font-size: 26px;
            line-height: 37px;
          }
          .section-five-side-content-des {
            color: rgba(80, 80, 80, 1);
            font-size: 16px;
            margin-top: 40px;
            line-height: 26px;
          }
        }
        .section-five-side-content-date {
          position: absolute;
          bottom: 60px;
          right: 5%;
          display: flex;
          flex-direction: column;
          align-items: center;
          & > div {
            font-size: 40px;
            color: rgba(51, 51, 51, 1);
            font-weight: bold;
            font-family: 'mySecondFont';
            &:nth-child(2) {
              font-size: 14px;
              color: rgba(152, 152, 152, 1);
              font-weight: normal;
              margin-top: 10px;
            }
          }
        }
        .section-five-side-content-image {
          width: 40%;
          height: 278px;
          position: absolute;
          top: -90px;
          right: -21.3%;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
      }
    }
    .section-five-pagination {
      position: absolute;
      bottom: 60px;
      z-index: 10;
      left: 8%;
      ::v-deep .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        overflow: hidden;
        border-radius: 0;
        background: rgba(80, 80, 80, 1);
        margin: 0 8px;
      }
    }
  }
  ::v-deep .el-dialog {
    height: 70vh;
    background: none;
    box-shadow: none;
    .el-dialog__close {
      background: #fff;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      color: #333;
    }
    .el-dialog__header {
      width: 0;
      height: 0;
      padding: 0;
      .el-dialog__headerbtn {
        z-index: 1000;
      }
    }
    .el-dialog__body {
      padding: 0;
      background: none;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      outline: none;
      video {
        width: 100%;
        height: 100%;
        outline: none;
      }
    }
  }
}
</style>